<template>
  <div>
    <p class="text-start font-weight-bolder font-medium-5">
      {{ $t('Update Discount') }}
    </p>
    <div>
      <b-row>
        <div class="ml-1">
          <span class="font-weight-bolder font-medium-2 d-block ml-2 mb-sm-1">
            {{ $t('Discount Reach') }}
          </span>
        </div>
      </b-row>
    </div>
    <div>
      <b-col md="3">
        <b-skeleton
          width="100%"
          height="50px"
          class="mb-2"
        />
      </b-col>
    </div>
    <b-row>
      <b-col md="6">
        <b-skeleton type="input" />
      </b-col>
      <b-col md="6">
        <b-skeleton type="input" />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="6">
        <b-skeleton type="input" />
      </b-col>
      <b-col md="6">
        <b-skeleton type="input" />
      </b-col>
    </b-row>
    <b-row class="pt-2">
      <b-col md="12">
        <b-skeleton type="input" />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="6">
        <b-skeleton type="input" />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="6">
        <b-skeleton type="input" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BSkeleton } from 'bootstrap-vue'

export default {
  name: 'Skeleton',
  components: {
    BRow,
    BCol,
    BSkeleton,
  },
}
</script>

<style scoped>

</style>
